<template>
  <div id="invoice-list" class="data-list-container">

    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <vx-card collapse="false" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <!--Filter by date-->
      <div>
        <h6 class="mt-5 mb-2">Rango de fechas</h6>
        <div class="vx-row">
          <div class="vx-col md:w-1/2">
            <datepicker :language="es" label="Desde" v-model="startDate" id="startDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
          <div class="vx-col md:w-1/2">
            <datepicker :language="es" label="Gasta" v-model="endDate" id="endDate"
                        v-validate="'required'" name="startDate"></datepicker>
          </div>
        </div>
      </div>
      <!--Filter by local-->
      <div>
        <div class="vx-row">
          <div class="vx-col md:w-1/2">
            <h6 class="mt-5 mb-2">Selecciona local</h6>
            <v-select label="name" :options="warehouses" :clearable="false"
                      :dir="$vs.rtl ? 'rtl' : 'ltr'"
                      v-model="local" class="mb-4 md:mb-0" name="account" id="account"/>
          </div>
        </div>
      </div>
      <div class="vx-col mt-2">
        <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
      </div>
    </vx-card>

    <detail
        :data="sidebarData"
        :isSidebarActive="showDetailSidebar"
        @closeSidebar="showDetailSidebar=false"/>

    <vs-table
        v-model="selected"
        pagination
        max-items="20"
        search
        stripe
        :data="list">

      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4 mb-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="exportToExcel">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="exportToExcelWarehouse">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar Almacén</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="exportToQlik">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar detalle</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="$router.push('/sales/import-product-sales')">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Importar ventas</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="sync">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Sync</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>

      <template slot="thead">
        <vs-th sort-key="type">Tipo</vs-th>
        <vs-th sort-key="local">Local</vs-th>
        <vs-th sort-key="createdAt">Fec. de registro</vs-th>
        <vs-th sort-key="serie">Serie doc.</vs-th>
        <vs-th sort-key="nroDoc">Nro. doc</vs-th>
        <vs-th sort-key="provider">Cliente</vs-th>
        <vs-th sort-key="currency">Moneda</vs-th>
        <vs-th sort-key="subTotal">Sub total</vs-th>
        <vs-th sort-key="discounts">Descuentos</vs-th>
        <vs-th sort-key="base">Base Imponible</vs-th>
        <vs-th sort-key="igv">IGV</vs-th>
        <vs-th sort-key="total">Total</vs-th>
        <vs-th sort-key="series">Serie</vs-th>
        <vs-th>Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p v-if="tr.invoiceType==='F'">Factura</p>
            <p v-if="tr.invoiceType==='B'">Boleta</p>
            <p v-if="tr.invoiceType==='NC'">Nota de crédito</p>
          </vs-td>

          <vs-td>
            <p>{{ tr.establishment ? tr.establishment.name : '' }}</p>
          </vs-td>

          <vs-td>
            <p>{{ tr.createdAt | date(true) }}</p>
          </vs-td>

          <vs-td>
            <p>{{ tr.serial }}</p>
          </vs-td>

          <vs-td>
            <p> {{ tr.sequence }} </p>
          </vs-td>

          <vs-td>
            <p> {{ tr.client ? tr.client.businessName : '' }} </p>
          </vs-td>

          <vs-td>
            <p>PEN</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: `${tr.currency}`
                }).format(tr.footer.subTotal)
              }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: `${tr.currency}`
                }).format(tr.footer.discount.value + tr.footer.globalDiscount.value)
              }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: `${tr.currency}`
                }).format(tr.footer.taxedOperations)
              }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: `${tr.currency}`
                }).format(tr.footer.mainTax)
              }}</p>
          </vs-td>

          <vs-td>
            <p>{{
                Intl.NumberFormat('en-IN', {
                  style: 'currency',
                  currency: `${tr.currency}`
                }).format(tr.footer.total)
              }}</p>
          </vs-td>

          <vs-td>
            <p>{{ tr.note }}</p>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon title="Detalle" icon="EyeIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="sidebarData= tr, showDetailSidebar= true"/>

            <feather-icon class="ml-2" title="Ver PDF" icon="ExternalLinkIcon"
                          svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="openPDF(tr.publicPdfUrl)"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import Detail from './Detail'
import Datepicker from 'vuejs-datepicker'

import { db, Timestamp } from '@/firebase/firebaseConfig'
import accounts from '../../data/acounts.json'
import costs from '../../data/costs.json'

import { es } from 'vuejs-datepicker/dist/locale'

const _ = require('lodash')

accounts.forEach((a) => {
  a.label = a.id + ' - ' + a.value
})

costs.forEach((c) => {
  c.label = c.id + ' - ' + c.value
})

export default {
  components: {
    Detail,
    Datepicker
  },
  data () {
    return {
      selected: null,
      itemsPerPage: 10,
      isMounted: false,
      list: [],
      products: [],
      initProgress: false,
      accounts: accounts,
      costs: costs,
      warehouses: [],
      startDate: null,
      endDate: null,
      local: {},
      es: es,

      // Data Sidebar
      newSidebar: false,
      showDetailSidebar: false,
      showDetractionSidebar: false,
      showPaymentsSidebar: false,
      sidebarData: {}
    }
  },
  computed: {
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.list.length
    }
  },
  async created () {
    try {
      this.initProgress = true
      await this.loadSales()
      await this.loadWarehouses()
      await this.loadProducts()
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    /**
     * Update state
     **/
    switchState (brand) {
      let objectRef = db.collection('providers').doc(brand.id)
      objectRef.update({
        state: brand.state
      }).then(() => {
        this.$vs.notify({
          color: 'success',
          title: 'Proveedores',
          text: 'La modificación fue aplicada.'
        })
      }).catch(() => {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      })
    },
    /**
     * Add data of sidebar
     * */
    addDataSideBar (o) {
      this.list.unshift(o)
    },
    /**
     * Update data of sidebar
     * */
    updateDataSideBar (o) {
      const index = this.list.findIndex((d) => d.id === o.id)
      Object.assign(this.list[index], o)
    },
    /**
     * Export to excel
     */
    exportToExcel () {
      import('@/vendor/Export2Excel').then(async excel => {
        this.initProgress = true
        const list = _.cloneDeep(this.list)
        const newList = []
        const formatForDoc = '0000000000'
        const formatDate = '00'
        list.forEach((l) => {
          const index = newList.findIndex((n) => (n.serial === l.serial) && n.sequence === l.sequence)
          if (index === -1) {
            newList.push(l)
          }
        })
        for await (let i of newList) {
          if (i.parentId && i.parentId.length > 0) {
            i.ref = await this.getParent(i.parentId)
          }
          else {
            i.ref = null
          }
          i.status = 0 + ''
          i.fechdoc = (formatDate.substr((i.issueDate.getDate() + '').length) + (i.issueDate.getDate() + '')) + '/' + (formatDate.substr((i.issueDate.getMonth() + 1 + '').length) + (i.issueDate.getMonth() + 1 + '')) + '/' + formatDate.substr((i.issueDate.getFullYear() + '').length) + (i.issueDate.getFullYear() + '')
          // Type doc
          if (i.isTicket) {
            i.tipdoc = '03'
          }
          else if (i.isInvoice) {
            i.tipdoc = '01'
          }
          else if (i.isCreditNote) {
            i.tipdoc = '07'
          }
          // End
          i.maqreg = ''
          i.serdoc = i.serial
          i.numdoc = formatForDoc.substr(i.sequence.length) + i.sequence
          i.numdocfinal = ''
          i.tipdid = (i.client.documentNumber === '00000000' ? '0' : this.getTypeDoc(i.client.documentIdentityType))
          i.nmruc = i.client.documentNumber
          i.ctactename = (i.client.documentNumber === '00000000' ? 'CLIENTES VARIOS' : i.client.businessName)
          i.afectoigvid = '1'
          i.baseimpo = i.isCreditNote ? parseFloat((i.footer.taxedOperations * -1).toFixed(2)) : parseFloat(i.footer.taxedOperations.toFixed(2))
          i.inafecto = 0
          i.pigv = 18
          i.migv = i.isCreditNote ? parseFloat((i.footer.mainTax * -1).toFixed(2)) : parseFloat(i.footer.mainTax.toFixed(2))
          i.total = i.isCreditNote ? parseFloat((i.footer.total * -1).toFixed(2)) : parseFloat(i.footer.total.toFixed(2))
          i.estabsunat = '0001'
          i.cuentaid = '7012101'
          i.glosa = 'VENTA DE MERCADERIA'
          i.moneda = 1
          i.tipref = (i.ref ? this.getTypeDocInvoice(i.ref) : '')
          i.serref = (i.ref ? (i.ref.serial) + '' : '')
          i.numref = (i.ref ? i.ref.sequence + '' : '')
          i.fechref = '' + (i.ref ? (formatDate.substr((i.ref.issueDate.getDate() + '').length) + (i.ref.issueDate.getDate() + '')) + '/' + (formatDate.substr((i.ref.issueDate.getMonth() + 1 + '').length) + (i.ref.issueDate.getMonth() + 1 + '')) + '/' + formatDate.substr((i.ref.issueDate.getFullYear() + '').length) + (i.ref.issueDate.getFullYear() + '') : '')
          i.condicionpago = '002'
          i.fechvcto = '' + (formatDate.substr((i.issueDate.getDate() + '').length) + (i.issueDate.getDate() + '')) + '/' + (formatDate.substr((i.issueDate.getMonth() + 1 + '').length) + (i.issueDate.getMonth() + 1 + '')) + '/' + formatDate.substr((i.issueDate.getFullYear() + '').length) + (i.issueDate.getFullYear() + '')
          i.cencosid = '60004'
          i.cuentaidt = '1213101'
          i.vendedorid = '' + i.user._id.substr(0, 4).toUpperCase()
          i.vendedor = i.user.firstname + ' ' + i.user.lastname
          console.log(i)
        }
        const headerVal = ['status', 'fechdoc', 'tipdoc', 'maqreg', 'serdoc', 'numdoc', 'numdocfinal', 'tipdid', 'nmruc', 'ctactename', 'afectoigvid', 'baseimpo', 'inafecto', 'pigv', 'migv', 'total', 'estabsunat', 'cuentaid', 'glosa', 'moneda', 'tipref', 'serref', 'numref', 'fechref', 'condicionpago', 'fechvcto', 'cencosid', 'cuentaidt', 'vendedorid', 'vendedor']
        const headerTitle = ['status', 'fechdoc', 'tipdoc', 'maqreg', 'serdoc', 'numdoc', 'numdocfinal', 'tipdid', 'nmruc', 'ctactename', 'afectoigvid', 'baseimpo', 'inafecto', 'pigv', 'migv', 'total', 'estabsunat', 'cuentaid', 'glosa', 'moneda', 'tipref', 'serref', 'numref', 'fechref', 'condicionpago', 'fechvcto', 'cencosid', 'cuentaidt', 'vendedorid', 'vendedor']
        const data = this.formatJson(headerVal, newList)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Registro de ventas',
          autoWidth: this.cellAutoWidth,
          bookType: 'xlsx'
        })
        this.initProgress = false
      })
    },
    /**
     * Export to excel
     */
    exportToExcelWarehouse () {
      import('@/vendor/Export2Excel').then(async excel => {
        this.initProgress = true
        const list = _.cloneDeep(this.list)
        const newList = []
        const formatForDoc = '0000000000'
        const formatDate = '00'
        list.forEach((l) => {
          const index = newList.findIndex((n) => (n.serial === l.serial) && n.sequence === l.sequence)
          if (index === -1) {
            newList.push(l)
          }
        })
        for await (let i of newList) {
          if (i.parentId && i.parentId.length > 0) {
            i.ref = await this.getParent(i.parentId)
          }
          else {
            i.ref = null
          }
          i.status = 0 + ''
          i.fechdoc = (formatDate.substr((i.issueDate.getDate() + '').length) + (i.issueDate.getDate() + '')) + '/' + (formatDate.substr((i.issueDate.getMonth() + 1 + '').length) + (i.issueDate.getMonth() + 1 + '')) + '/' + formatDate.substr((i.issueDate.getFullYear() + '').length) + (i.issueDate.getFullYear() + '')
          // Type doc
          if (i.isTicket) {
            i.tipdoc = '03'
          }
          else if (i.isInvoice) {
            i.tipdoc = '01'
          }
          else if (i.isCreditNote) {
            i.tipdoc = '07'
          }
          // End
          i.maqreg = ''
          i.serdoc = i.serial
          i.numdoc = formatForDoc.substr(i.sequence.length) + i.sequence
          i.numdocfinal = ''
          i.tipdid = (i.client.documentNumber === '00000000' ? '0' : this.getTypeDoc(i.client.documentIdentityType))
          i.nmruc = i.client.documentNumber
          i.ctactename = (i.client.documentNumber === '00000000' ? 'CLIENTES VARIOS' : i.client.businessName)
          i.afectoigvid = '1'
          i.baseimpo = i.isCreditNote ? parseFloat((i.footer.taxedOperations * -1).toFixed(2)) : parseFloat(i.footer.taxedOperations.toFixed(2))
          i.inafecto = 0
          i.pigv = 18
          i.migv = i.isCreditNote ? parseFloat((i.footer.mainTax * -1).toFixed(2)) : parseFloat(i.footer.mainTax.toFixed(2))
          i.total = i.isCreditNote ? parseFloat((i.footer.total * -1).toFixed(2)) : parseFloat(i.footer.total.toFixed(2))
          i.estabsunat = '0001'
          i.cuentaid = '7012101'
          i.glosa = 'VENTA DE MERCADERIA'
          i.moneda = 1
          i.tipref = (i.ref ? this.getTypeDocInvoice(i.ref) : '')
          i.serref = (i.ref ? (i.ref.serial) + '' : '')
          i.numref = (i.ref ? i.ref.sequence + '' : '')
          i.fechref = '' + (i.ref ? (formatDate.substr((i.ref.issueDate.getDate() + '').length) + (i.ref.issueDate.getDate() + '')) + '/' + (formatDate.substr((i.ref.issueDate.getMonth() + 1 + '').length) + (i.ref.issueDate.getMonth() + 1 + '')) + '/' + formatDate.substr((i.ref.issueDate.getFullYear() + '').length) + (i.ref.issueDate.getFullYear() + '') : '')
          i.condicionpago = '002'
          i.fechvcto = '' + (formatDate.substr((i.issueDate.getDate() + '').length) + (i.issueDate.getDate() + '')) + '/' + (formatDate.substr((i.issueDate.getMonth() + 1 + '').length) + (i.issueDate.getMonth() + 1 + '')) + '/' + formatDate.substr((i.issueDate.getFullYear() + '').length) + (i.issueDate.getFullYear() + '')
          i.cencosid = '60004'
          i.cuentaidt = '1213101'
          i.vendedorid = '' + i.user._id.substr(0, 4).toUpperCase()
          i.serie = i.note
        }
        const headerVal = ['status', 'fechdoc', 'tipdoc', 'maqreg', 'serdoc', 'numdoc', 'numdocfinal', 'tipdid', 'nmruc', 'ctactename', 'afectoigvid', 'baseimpo', 'inafecto', 'pigv', 'migv', 'total', 'estabsunat', 'cuentaid', 'glosa', 'moneda', 'tipref', 'serref', 'numref', 'fechref', 'condicionpago', 'fechvcto', 'cencosid', 'cuentaidt', 'vendedorid', 'serie']
        const headerTitle = ['status', 'fechdoc', 'tipdoc', 'maqreg', 'serdoc', 'numdoc', 'numdocfinal', 'tipdid', 'nmruc', 'ctactename', 'afectoigvid', 'baseimpo', 'inafecto', 'pigv', 'migv', 'total', 'estabsunat', 'cuentaid', 'glosa', 'moneda', 'tipref', 'serref', 'numref', 'fechref', 'condicionpago', 'fechvcto', 'cencosid', 'cuentaidt', 'vendedorid', 'serie']
        const data = this.formatJson(headerVal, newList)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Registro de ventas',
          autoWidth: this.cellAutoWidth,
          bookType: 'xlsx'
        })
        this.initProgress = false
      })
    },
    /**
     * Export to qlik
     */
    exportToQlik () {
      import('@/vendor/Export2Excel').then(async excel => {
        this.initProgress = true
        const list = _.cloneDeep(this.list)
        const newList = []
        list.forEach((l) => {
          const index = newList.findIndex((n) => (n.serial === l.serial) && n.sequence === l.sequence)
          if (index === -1) {
            newList.push(l)
          }
        })
        const listForExcel = []
        newList.forEach((l) => {
          l.detail.forEach((p) => {
            const productFind = p.sku ? this.products.find((p2) => p.sku.toUpperCase() == p2.sku.toUpperCase()) : null
            if (productFind) {
              listForExcel.push({
                sku: p.sku ? p.sku.toUpperCase() : 'REVIEW',
                name: p.name,
                category: productFind.category.name,
                brand: productFind.brand.name,
                saleDate: l.createdAt.getFullYear() + '/' + (l.createdAt.getMonth() + 1) + '/' + l.createdAt.getDate(),
                year: l.createdAt.getFullYear(),
                month: this.getMonth(l.createdAt.getMonth()),
                order: parseInt(l.createdAt.getFullYear() + '' + l.createdAt.getMonth()),
                quantity: p.quantity,
                singlePrice: p.price + p.taxes[0].price,
                discount: p.discount.value * 1.18,
                total: ((p.price + p.taxes[0].price) * p.quantity) - (p.discount.value * 1.18 * p.quantity),
                local: l.establishment.name,
                seller: l.user.firstName + ' ' + l.user.lastName,
                doc: l.serial + '-' + l.sequence,
                sequence: l.sequence,
                type: l.invoiceType === 'NC' ? 'Anulación' : 'Venta',
                vendedor: l.user.firstname + ' ' + l.user.lastname
              })
            }
          })
        })
        const headerVal = ['sku', 'name', 'category', 'brand', 'saleDate', 'year', 'month', 'quantity', 'singlePrice', 'discount', 'total', 'local', 'doc', 'sequence', 'type', 'order', 'vendedor']
        const headerTitle = ['SKU', 'Nombre', 'Categoría', 'Marca', 'Fecha de venta', 'Año', 'Mes', 'Cantidad', 'Precio', 'Descuento', 'Total', 'Local', 'Documento', 'Secuencia', 'Tipo', 'Orden', 'vendedor']
        const data = this.formatJson(headerVal, listForExcel)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Qlik Sense',
          autoWidth: this.cellAutoWidth,
          bookType: 'xlsx'
        })
        this.initProgress = false
      })
    },
    getMonth (month) {
      switch (month) {
        case 0:
          return 'Enero'
        case 1:
          return 'Febrero'
        case 2:
          return 'Marzo'
        case 3:
          return 'Abril'
        case 4:
          return 'Mayo'
        case 5:
          return 'Junio'
        case 6:
          return 'Julio'
        case 7:
          return 'Agosto'
        case 8:
          return 'Septiembre'
        case 9:
          return 'Octubre'
        case 10:
          return 'Noviembre'
        case 11:
          return 'Diciembre'
      }
    },
    /**
     * Get parent ID
     * */
    async getParent (parentId) {
      try {
        const querySnap = await db.collection('sales').where('_id', '==', parentId).get()
        let docSale = {}
        querySnap.forEach((doc) => {
          docSale = {
            id: doc.id,
            ...doc.data()
          }
          docSale.issueDate = doc.data().issueDate.toDate()
        })
        if (docSale.id) {
          return docSale
        }
        else {
          return null
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    /**
     * Get sales
     * */
    async loadSales () {
      const querySnapshot = await db.collection('sales').limit(1000).orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let obj = {
          ...doc.data()
        }
        obj.createdAt = doc.data().createdAt.toDate()
        obj.issueDate = doc.data().createdAt.toDate()
        if (obj.ref) {
          obj.ref.issueDate = doc.data().ref.issueDate.toDate()
        }
        obj.id = doc.id

        if (obj.company) {
          this.list.push(obj)
        }
      })
    },
    /**
     * Filter by date
     */
    async filterByDate () {
      try {
        if (this.startDate && this.endDate) {
          this.initProgress = true
          this.list = []
          // Set start date
          this.startDate.setHours(0)
          this.startDate.setMinutes(0)
          // Set end dateU
          this.endDate.setHours(23)
          this.endDate.setMinutes(59)

          const starTimes = Timestamp.fromDate(this.startDate)
          const endTimes = Timestamp.fromDate(this.endDate)
          let querySnapshot = {}
          if (this.local.id) {
            querySnapshot = await db.collection('sales')
                .where('issueDate', '>=', starTimes)
                .where('issueDate', '<=', endTimes)
                .where('establishment.id', '==', this.local.arisale).get()
          }
          else {
            querySnapshot = await db.collection('sales')
                .where('issueDate', '>=', starTimes)
                .where('issueDate', '<=', endTimes).get()
          }
          querySnapshot.forEach((doc) => {
            let obj = {
              ...doc.data()
            }
            if (obj.ref) {
              obj.ref.issueDate = doc.data().ref.issueDate.toDate()
            }
            obj.createdAt = doc.data().createdAt.toDate()
            obj.issueDate = doc.data().issueDate.toDate()
            obj.id = doc.id
            this.list.push(obj)
          })
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Get warehouses
     * @returns {Promise<void>}
     */
    async loadWarehouses () {
      this.warehouses = []
      this.warehouses.push({
        id: null,
        name: 'Todos'
      })
      const querySnapshot = await db.collection('warehouses').where('state', '==', true).orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let warehouse = {
          id: doc.id,
          name: doc.data().name,
          arisale: doc.data().arisale
        }
        this.warehouses.push(warehouse)
      })
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    async resetFilters () {
      this.initProgress = true
      this.list = []
      this.startDate = null
      this.endDate = null
      this.local = {}
      await this.loadSales()
      this.$refs.filterCard.removeRefreshAnimation()
      this.initProgress = false
    },
    /**
     * Open URL
     */
    openPDF (link) {
      window.open(link)
    },
    /**
     Get type doc
     */
    getTypeDoc (type) {
      if (type === 'RUC') {
        return '6'
      }
      else if (type === 'DNI') {
        return '1'
      }
      else if (type === 'CE') {
        return '4'
      }
      else if (type === 'PAS') {
        return '7'
      }
    },
    /**
     Get type doc invoice
     */
    getTypeDocInvoice (invoice) {
      console.log(invoice)
      if (invoice.isTicket) {
        return '03'
      }
      else if (invoice.isInvoice) {
        return '01'
      }
      else if (invoice.isCreditNote) {
        return '07'
      }
    },
    /**
     * Load products
     * @returns {Promise<void>}
     */
    async loadProducts () {
      this.products = []
      const querySnapshot = await db.collection('products').orderBy('createdAt', 'desc').get()
      querySnapshot.forEach((doc) => {
        let product = {
          id: doc.id,
          category: doc.data().category,
          sku: doc.data().sku,
          brand: doc.data().brand
        }
        this.products.push(product)
      })
    },
    /**
     * Sync sales
     */
    sync () {
      this.list.forEach((l) => {
        db.collection('sales2').add({
          ...l
        })
      })
    }
  }
}
</script>

<style lang="scss">
#invoice-list {

  .vs-table--tbody-table {
    min-width: 1700px !important;
  }

  .vs-con-table {
    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
